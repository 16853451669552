
import { Component, Mixins } from 'vue-property-decorator';

import HeaderInfosMixin from '../header-infos';
import CollectionMixin from '@/mixins/data-operations/collection/collection-mixin';
import { StorageToTables } from '@/components/data-operations/runs-collections';

@Component({
	components: { StorageToTablesCollectionListing: StorageToTables.collectionListing },
})
export default class StorageToTablesRunsListingView extends Mixins(HeaderInfosMixin, CollectionMixin) {}
